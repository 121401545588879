import React from "react"

import SEO from "../shared_components/seo"

import "@fortawesome/fontawesome-free/css/all.css"

const IndexPage = () => {
  return (
    <div>
      <SEO />
      <h1 style={{textAlign: 'center', fontSize: '5vmax'}}>QQQ Group</h1>
    </div>
  )
}

export default IndexPage
